 export function downloadFile(url, headers, filename) {
    function handleFile(data) {
        const datas = this.response || data
        console.warn(datas)
        // var file = window.URL.createObjectURL(datas)
        var file = URL.createObjectURL(new Blob([datas]))
        filename = filename || url.split('/').pop()
        var a = document.createElement('a')
        if ('download' in a) {
            a.href = file
            a.download = filename
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } else {
            window.open(file)
        }
    }
    var request = new XMLHttpRequest()
    request.responseType = 'blob'
    request.onload = handleFile
    request.open('GET', url)
    for (var prop in headers) {
        request.setRequestHeader(prop, headers[prop])
    }
    request.send()
}
/**
 * 获取任意时间
 */
export function getDate(date, AddDayCount = 0, str = 'day') {
    if (!date) {
        date = new Date()
    }
    if (typeof date !== 'object') {
        date = date.replace(/-/g, '/')
    }
    const dd = new Date(date)
    switch (str) {
        case 'day':
            dd.setDate(dd.getDate() + AddDayCount) // 获取AddDayCount天后的日期
            break
        case 'month':
            if (dd.getDate() === 31 && AddDayCount>0) {
                dd.setDate(dd.getDate() + AddDayCount)
            } else {
                const preMonth = dd.getMonth()
                dd.setMonth(preMonth + AddDayCount) // 获取AddDayCount天后的日期
                const nextMonth = dd.getMonth()
                // 处理 pre 切换月份目标月份为2月没有当前日(30 31) 切换错误问题
                if(AddDayCount<0 && preMonth!==0 && nextMonth-preMonth>AddDayCount){
                    dd.setMonth(nextMonth+(nextMonth-preMonth+AddDayCount))
                }
                // 处理 next 切换月份目标月份为2月没有当前日(30 31) 切换错误问题
                if(AddDayCount>0 && nextMonth-preMonth>AddDayCount){
                    dd.setMonth(nextMonth-(nextMonth-preMonth-AddDayCount))
                }
            }
            break
        case 'year':
            dd.setFullYear(dd.getFullYear() + AddDayCount) // 获取AddDayCount天后的日期
            break
    }
    const y = dd.getFullYear()
    const m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1 // 获取当前月份的日期，不足10补0
    const d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate() // 获取当前几号，不足10补0
    return {
        fullDate: y + '-' + m + '-' + d,
        year: y,
        month: m,
        date: d,
        day: dd.getDay()
    }
}

